import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import './Navbar.css';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  // Effect to handle body overflow based on mobile menu state
  useEffect(() => {
    document.body.style.overflow = clicked ? 'hidden' : 'auto';

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [clicked]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLinkClick = (index) => {
    setClicked(false); // Close the menu when a link is clicked
    setActiveLink(index);
  };

  return (
    <nav>
      <Link to="/">
        <h1 style={{ lineHeight: '12px' }}>
          Way To Intern
        </h1>
      </Link>

      <div>
        <ul id="navbar" className={clicked ? 'active' : ''}>
          <li className={activeLink === 0 ? 'active' : ''} onClick={() => handleLinkClick(0)}>
            <Link to="/" className={activeLink === 0 ? 'active' : ''}>Home</Link>
          </li>
          <li className={activeLink === 1 ? 'active' : ''} onClick={() => handleLinkClick(1)}>
            <Link to="/About" className={activeLink === 1 ? 'active' : ''}>About</Link>
          </li>
          <li className={activeLink === 2 ? 'active' : ''} onClick={() => handleLinkClick(2)}>
            <Link to="/Services" className={activeLink === 2 ? 'active' : ''}>Services</Link>
          </li>
          <li className={activeLink === 3 ? 'active' : ''} onClick={() => handleLinkClick(3)}>
            <Link to="/Contact" className={activeLink === 3 ? 'active' : ''}>Contact</Link>
          </li>
        </ul>
      </div>

      <div id="mobile" onClick={handleClick}>
        <i id="bar" className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
    </nav>
  );
};

export default Navbar;
