import React from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import './Intro.css'
import Team from '../../Assets/team-work.jpeg'

const Intro = () => {
    return (
        <>
            <div className="intro">
                <div className="motive">
                    <div className="comp">
                        <i class="fa-solid fa-laptop-code"></i>
                        <p>
                            Skill Development
                        </p>
                    </div>

                    <div className="comp">
                        <i class="fa-solid fa-person-chalkboard"></i>
                        <p>
                            Mentoring
                        </p>
                    </div>

                    <div className="comp">
                        <i class="fa-solid fa-handshake"></i>
                        <p>
                            Networking
                        </p>
                    </div>

                    <div className="comp">
                        <i class="fa-solid fa-user-tie"></i>
                        <p>
                            Leadership
                        </p>
                    </div>
                </div>

                <div className="brief">

                    <h1>Join Us!</h1>
                    <p>At Way To Intern, we are committed to enhancing your skill set and providing opportunities for growth and learning. Join our community today and unlock your potential!</p>
                    <a href="https://drive.google.com/file/d/1CcfEP1ffrWPGRCbKQFOOCb9rjdwodAfM/view?usp=sharing" target="_blank">
                        <button>Know More</button>
                    </a>

                </div>
            </div>
        </>
    )
}

export default Intro
