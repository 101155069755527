import React from 'react';
import './Moving.css'; 

const Moving = () => {
  return (
    <div className="scroll-container">
      <p>Way To Intern - Learn together, Grow Together</p> 



    </div>

    
  );
};

export default Moving;
