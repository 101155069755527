import React from 'react'
import './Banner.css'

const Banner = () => {
  return (
    <>
    <div className="main">
      <h1>Way To Intern !</h1>
      <h5>Learn Together, Grow Together</h5>

    </div>
      
      </>
  )
}

export default Banner
