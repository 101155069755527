import React, { useState, useEffect } from 'react';
import './Popup.css'; // Import the CSS file for styling

const Popup = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isClosing, setIsClosing] = useState(false);

    const closePopup = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsVisible(false);
            setIsClosing(false);
        }, 500); // Match the duration of the CSS transition
    };

    useEffect(() => {
        // Optional: Logic for mounting or unmounting
    }, []);

    return (
        <div className={`popup ${isVisible ? (isClosing ? 'hide' : 'show') : ''}`}>
            <div className="popup-content">
                <span className="close-btn" onClick={closePopup}>&times;</span>
                <p style={{marginTop:"-20px"}}><br />Hey, Welcome to Our Website <br /><br />Join us on Telegram</p>
                <a href="https://t.me/waytointern">
                    <button className='popup-btn'>Click Here</button>
                </a>
            </div>
        </div>
    );
};

export default Popup;
