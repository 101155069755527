import React from 'react'
import './Benefits.css'

const Benefits = () => {
    return (
        <>
        <div className="BenefitSec">
        <h1>Why Choose Us!</h1>
            <h4>Active Community</h4>
            <hr style={{color:"black", width:" 80%", background:"rgb(0, 151, 50)", border:"none", height:"2px", marginBottom:"50px"}} />

            <div className="benefit">
                <div className="left">
                    <p>1. Research</p>
                </div>

                <div className="right">
                    <p>To enhance your chances of getting hired, it's essential to stay updated with new trending skills. Research emerging technologies, industry demands, and job market trends. Focus on in-demand skills like data analysis, artificial intelligence, digital marketing, cybersecurity, and remote collaboration tools. Continuously learn and adapt to stay competitive.</p>
                </div>
            </div>

            <div className="benefit benefit-1">
                <div className="right">
                    <p>Our community is fortunate to have experienced mentors and leaders who are committed to supporting your journey in acquiring new skills. They offer valuable insights, guidance, and advice, ensuring that you have the necessary support to succeed in your endeavors. Their expertise and mentorship will be instrumental in your growth and development.</p>
                </div>
                <div className="left">
                    <p>2. Expert Guidance</p>
                </div>
            </div>

            <div className="benefit">
                <div className="left">
                    <h3>3. Learn</h3>
                </div>

                <div className="right">
                    <p>Learning new skills boosts productivity. Identify areas for improvement, acquire skills in time management, organization, communication, problem-solving, and critical thinking. Explore tools and tech that streamline tasks and automate processes. Continual learning and skill development lead to enhanced productivity, and professional growth</p>
                </div>
            </div>

            <div className="benefit benefit-1">
                <div className="right">
                    <p>Sharing your valuable skills and roadmap with others is an excellent way to foster improvement. By sharing knowledge, insights, and experiences, you contribute to the growth and development of others. Collaboration and open communication create a supportive environment where everyone can learn from each other, leading to collective</p>
                </div>
                <div className="left">
                    <h3>4. Share</h3>
                </div>
            </div>

        </div>
            
        </>
    )
}

export default Benefits
