import React from 'react'
import './Services.css'

const Services = () => {
  return (
    <div>
        <div className='about-main' ><p style={{ fontSize: "20px", margin:"0" }}>Main    /   Services</p> <h4 style={{ fontSize: '28px', margin:"0" }}>Our Services</h4></div>

        <div className="service">
            <div className="services">
                <div className="left">
                    <p>1. Student Program</p>
                </div>

                <div className="right">
                    <p>To enhance your chances of getting hired, it's essential to stay updated with new trending skills. Research emerging technologies, industry demands, and job market trends. Focus on in-demand skills like data analysis, artificial intelligence, digital marketing, cybersecurity, and remote collaboration tools. Continuously learn and adapt to stay competitive.</p>
                </div>
            </div>

            <div className="services services-1">
                <div className="right">
                    <p>Our community is fortunate to have experienced mentors and leaders who are committed to supporting your journey in acquiring new skills. They offer valuable insights, guidance, and advice, ensuring that you have the necessary support to succeed in your endeavors. Their expertise and mentorship will be instrumental in your growth and development.</p>
                </div>
                <div className="left">
                    <p>2. Professional Development</p>
                </div>
            </div>

            <div className="services">
                <div className="left">
                    <h3>3. Resume Building</h3>
                </div>

                <div className="right">
                    <p>Learning new skills boosts productivity. Identify areas for improvement, acquire skills in time management, organization, communication, problem-solving, and critical thinking. Explore tools and tech that streamline tasks and automate processes. Continual learning and skill development lead to enhanced productivity, and professional growth</p>
                </div>
            </div>

            <div className="services services-1">
                <div className="right">
                    <p>Sharing your valuable skills and roadmap with others is an excellent way to foster improvement. By sharing knowledge, insights, and experiences, you contribute to the growth and development of others. Collaboration and open communication create a supportive environment where everyone can learn from each other, leading to collective</p>
                </div>
                <div className="left">
                    <h3>4. Website Design</h3>
                </div>
            </div>

            <div className="services">
                <div className="left">
                    <p>5. Business planning</p>
                </div>

                <div className="right">
                    <p>Business planning is crucial for success. It involves setting clear goals, understanding the market, defining your service offerings, and developing marketing strategies. Effective planning includes financial management, operational efficiency, risk management, and continuous performance evaluation. This strategic approach ensures growth, competitive advantage, and operational excellence.</p>
                </div>
            </div>

        </div>
<h5 style={{fontStyle:'italic', color:'rgb(0, 151, 50)', margin:'0 10%', fontSize:'20px'}}>
  *For any service proposal please <a href="https://wa.me/918764530346" style={{fontStyle:'italic', color:'rgb(0, 151, 50)',  fontSize:'20px', textDecoration:"underline"}}>Click here</a> or submit your details on contact us page
</h5>


    </div>
  )
}

export default Services
