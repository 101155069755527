import React from 'react'
import './Main.css'
import Banner from '../../Components/Banner/Banner'
import Intro from '../../Components/Intro/Intro'
import Benefits from '../../Components/Benefits/Benefits'

const Main = () => {
  return (
    <div>
      <Banner/>
      <Intro />
      <Benefits />
      
      {/* <>
    <div className="main">
      <h1>Way To Intern</h1>
      <h5>Learn Together, Grow Together</h5>

    </div>
      
      </> */}
    </div>
  )
}

export default Main
