import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const form = useRef();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(phoneNumber)) {
      sendEmail(); // Proceed with sending the email if the phone number contains only digits
    } else {
      setError('Please enter a valid phone number with digits only.');
    }
  };

  const sendEmail = () => {
    console.log("Form submitted");

    emailjs
      .sendForm(
        'service_9uw5g0v',
        'template_s56xy3v',
        form.current,
        'Vh09bqOnCZb8qhyDq'
      )
      .then(
        (result) => {
          console.log('SUCCESS:', result.text);
          alert("Your message has been received successfully. We will reach out to you soon!");
          form.current.reset();
          setPhoneNumber('');  // Reset phone number state
          setError('');  // Clear any error message
        },
        (error) => {
          console.log('FAILED:', error.text);
          alert('Failed to send message. Please try again.');
        }
      );
  };

  return (
    <div>
      <div className='about-main'>
        <p style={{ fontSize: "20px", margin: "0" }}>Main / Contact</p>
        <h4 style={{ fontSize: '28px', margin: "0" }}>Contact Us</h4>
      </div>

      <div className="contact-form1">
        <form ref={form} onSubmit={handleSubmit}>
          <input type="text" name="user_name" placeholder="Enter Your Name" required />
          <input type="email" name="user_email" placeholder="Enter Your email" required />
          <input
            type="tel"
            name="user_number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter Your Mobile Number"
            required
            pattern="\d*" // Only allow digits (numbers)
          />
          {error && <p style={{ color: 'red', fontSize: '12px', textAlign: 'left' }}>{error}</p>}
          <input type="text" name="user_subject" placeholder="Enter Your Subject" required />
          <textarea
            name="message"
            cols="40"
            rows="10"
            placeholder="Enter Your Message"
            required
            style={{ height: '150px', width: '80%' }}>
          </textarea>
          <br />
          <input type="submit" value="Submit" className="send1" />
        </form>
      </div>
    </div>
  );
};

export default Contact;
