import React from 'react'
import './About.css'

const About = () => {
  return (
    <div>
        <div className='about-main' ><p style={{ fontSize: "20px", margin:"0" }}>Main    /   About</p> <h4 style={{ fontSize: '28px', margin:"0" }}>About Us</h4></div>
        <p className='aboutDesc'>Way To Intern is a vibrant and active skill development community dedicated to enhancing the skill sets of its members. Our platform serves as a catalyst for growth and learning, providing numerous activities and meetings to facilitate personal and professional development. <br /> <br />

One of our key offerings is organizing seminars and workshops led by industry experts. These events allow our members to gain in-depth knowledge and insights on specific skill areas. By participating in these sessions, students can broaden their understanding and expertise. Moreover, these events provide valuable networking opportunities, enabling students to connect with industry professionals, mentors, and like-minded peers.<br /> <br />

To further support our members, we produce informative videos covering a wide range of topics related to skill development, industry trends, and career guidance. These videos serve as a valuable resource, ensuring that our members stay informed and up-to-date with the latest developments in their respective fields.<br /> <br />

Our community thrives on the collaboration and dedication of students, mentors, and industry experts who share a common passion for continuous learning. We foster an inclusive and supportive environment where individuals can freely exchange ideas, seek guidance, and collaborate on various projects. We believe that by leveraging the collective knowledge and experiences of our members, we can drive meaningful growth and success.<br /> <br />

Through our platform, we empower students by equipping them with the necessary tools and resources to thrive in their chosen fields. We are committed to nurturing talent and providing a supportive ecosystem where individuals can unleash their full potential.<br /> <br />

Join Way To Intern and embark on a journey of skill development, networking, and personal growth. Together, we can pave the way to a brighter future.</p>
    </div>
  )
}

export default About
